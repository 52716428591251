.region-directlinks .hiag-shortcuts-view .view-content {
  margin: 8em 1.35rem;
  transform: translateY(1.35em);
}

.hiag-shortcut-button {
  .shortcut-title {
    transform: scale(120%);
    color: var(--black);
    font-weight: bold;
  }
}

.region-sub-content {
  .card {
    margin-bottom: 0;

    .card-divider {
      background-color: transparent;
      padding-bottom: 0;
    }
  }

  [class*='tab'] {
    background-color: transparent !important;

    & > [data-tabs-target] {
      background-color: transparent !important;
    }
  }
}

.hiag-menu-underlined-link > a::after {
  content: none !important;
}

.hiag-main-menu-tree-container {
  &.menu .is-active > a {
    color: var(--white);
  }
}

.view.hiag-tabs ul.hiag-tabs .tabs-title:not(.is-active) a,
.view.hiag-tabs .view-content > .tabs .tabs-title:not(.is-active) a {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);

  &:hover,
  &:active {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.fc {
  --fc-border-color: var(--secondary-color);
}

.hiag-main-menu-dropdown-container
  > .is-dropdown-submenu-parent
  > ul
  .is-submenu-item:hover
  > a,
.hiag-main-menu-dropdown-container
  > .is-dropdown-submenu-parent
  > ul
  .is-submenu-item.is-active
  > a {
  color: var(--white);
}

.view-display-id-block_aktuelles_footer a.footer-events strong {
  color: rgba(246, 246, 246, 0.65);
}

table.table-no-border {
  border: none !important;

  * {
    border: none !important;
  }
}

.img-add {
  mix-blend-mode: multiply;
}

.node--type-person.node--view-mode-hiag-person .node__content {
  flex-direction: row-reverse;
}

.view-display-id-block_news_buus .view-content {
  gap: 2.1rem;
  display: flex;
  flex-direction: column;

  .node--view-mode-hiag-accordion-title {
    align-items: center;
  }
}

.accordion {
  border-radius: 0 !important;

  .accordion-item,
  .accordion-title,
  .accordion-content {
    border-radius: 0;
    border-left: none !important;
    border-right: none !important;
  }

  .accordion-item:first-child > :first-child,
  :last-child:not(.is-active) > .accordion-title,
  .accordion-item:last-child > :last-child {
    border-radius: 0 !important;
  }
}

.region.region-slider {
  width: 100vw !important;
  max-width: 100vw !important;

  & > .block {
    margin-left: auto;
    margin-right: auto;
    max-width: 2560px;
  }
}

.view.hiag-tabs ul.hiag-tabs .tabs-title:not(.is-active) a:hover,
.view.hiag-tabs ul.hiag-tabs .tabs-title:not(.is-active) a:focus,
.view.hiag-tabs .view-content > .tabs .tabs-title:not(.is-active) a:hover,
.view.hiag-tabs .view-content > .tabs .tabs-title:not(.is-active) a:focus {
  color: var(--white);
  border-color: #034b20;
}

#block-hauptnavigation {
  .hiag-menu-underlined-link > a {
    color: var(--white);
  }

  .hiag-menu-underline {
    background-color: var(--white);
  }
}
